import { api } from "./axios";

const addNewQuestion = data => {
  return api.post("v1/question", data);
};

const getListQuestionByIdCourse = id => {
  return api.get(`v1/question/${id}`);
};

const getDetailQuestionById = id => {
  return api.get(`v1/question/detail/${id}`);
};

const updateQuestion = data => {
  return api.patch(`v1/question`, data);
};

const deleteQuestion = id => {
  return api.delete(`v1/question?id=${id}`);
};

const downloadFormatQuestion = () => {
  return api.get(`v1/question`, {
    responseType: "blob"
  });
};

export {
  addNewQuestion,
  getListQuestionByIdCourse,
  getDetailQuestionById,
  updateQuestion,
  downloadFormatQuestion,
  deleteQuestion
};

<template>
  <div>
    <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
      <div class="wrapper-select">
        <span>Show</span>
        <div class="select-row">
          <el-select
            class="select-primary pagination-select"
            size="mini"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
        <span>Row</span>
      </div>
      <div class="form-search">
        <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
      </div>
    </div>
    <el-table
      :data="queriedData"
      row-key="id"
      header-row-class-name="thead-light"
      @sort-change="sortChange"
      @selection-change="selectionChange"
    >
      <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
    </el-table>
    <div
      slot="footer"
      class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
    >
      <div class>
        <p class="card-category">
          Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
          <span
            v-if="selectedRows.length"
          >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
        </p>
      </div>
      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      ></base-pagination>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Select, Option, Tag } from 'element-ui'
import { BasePagination } from '@/components';
import { getListFeedbackbyCourse } from '@/services/course'
import Fuse from "fuse.js";
export default {
  name: 'ListFeedback',
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      searchedData: [],
      fuseSearch: null,
      propsToSearch: ['date', 'name', 'rating', 'description'],
      tableColumns: [
        {
          prop: 'date',
          label: 'Date',
          minWidth: 200,
          sortable: true
        },
        {
          prop: 'name',
          label: 'Student Name',
          minWidth: 250,
          sortable: true
        },
        {
          prop: 'rating',
          label: 'Rating',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'description',
          label: 'Description',
          minWidth: 200,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
    }
  },
  computed: {
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      } else {
        if (this.searchQuery) {
          result = [];
        }
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  methods: {
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getParticipant() {
      try {
        const { data } = await getListFeedbackbyCourse(this.$route.params.id)
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.getParticipant()
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch
          .search(this.searchQuery)
          .map(({ item }) => item);
      }
      this.searchedData = result;
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";

.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}

.el-tag {
  background-color: #fff !important;
  border-color: #fff !important;
  &.pass {
    color: $status-pass-ar-vr;
  }
  &.learn {
    color: $status-learn-ar-vr;
  }
  &.failed {
    color: $status-failed-ar-vr;
  }
}
</style>
import { api } from "./axios";

const getAllCourse = () => {
  return api.get("v1/course");
};

const addCourse = data => {
  return api.post("v1/course", data);
};

const getCourseById = id => {
  return api.get(`v1/course/${id}`);
};

const getMemberByCourse = id => {
  return api.get(`v1/course/member/${id}`);
};

const getListFeedbackbyCourse = id => {
  return api.get(`v1/feedback?id=${id}`);
};

const activeOrNonActive = data => {
  return api.put(`v1/course`, data);
};

const editCourse = data => {
  return api.patch(`v1/course`, data);
};

export {
  getAllCourse,
  getCourseById,
  activeOrNonActive,
  addCourse,
  editCourse,
  getMemberByCourse,
  getListFeedbackbyCourse
};

import { api } from "./axios";

const getListQna = id => {
  return api.get(`v1/qna/${id}`);
};

const getDetailQna = id => {
  return api.get(`v1/qna/detail/${id}`);
};

const answerQuestion = data => {
  return api.post(`v1/qna`, data);
};

export { getListQna, getDetailQna, answerQuestion };
